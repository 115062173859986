<!-- 协议 -->
<template>
  <page-container class="agreement-detail-container">
    <div style="padding: 1em" v-html="content"></div>
    <div class="agreement-detail-bottom-bar">
      <div class="is-read-container">
        <van-checkbox
            v-model="isRead"
        >
          我已阅读并同意《{{ contractTitle }}》
        </van-checkbox>
      </div>
      <div class="btn-container" style="" @click="() => !isRead && Toast('请先勾选已阅读并同意选项')">
        <van-button
            :disabled="!isRead"
            type="primary" block round
            @click="handleAgreeClick"
        >
          同意协议
        </van-button>
      </div>
    </div>
  </page-container>
</template>

<script>
import { Toast } from 'vant';
import flowChain from '@/util/flowChain';
import { getContractInfo, signContract } from '@/api/agreement';

export default {
  name: 'agreementDetail',
  data() {
    return {
      isRead: false,
      contractTitle: '',
      content: '',
    };
  },
  created() {
    let contractId = this.$route.query.contractId;
    getContractInfo({ contractId }).then(res => {
      let { contractTitle = '', content = '' } = res || {};
      Object.assign(this, { contractTitle, content });
    });
  },
  methods: {
    Toast,
    handleAgreeClick() {
      let contractId = this.$route.query.contractId;
      signContract({ contractId }).then(res => {
        if (res === true) {
          this.handleToNextPage();
        }
      });
    },
    handleToNextPage() {
      // 获取用户当前所需要进行任务流程
      flowChain().then(location => {
        if (location)
          this.$router.replace(location);
        else
          this.$router.replace('/home');
      });
    },
  },
};
</script>

<style lang="less" scoped>
.agreement-detail-container {
  padding: 0 0 110px 0;

  /deep/ img {
    max-width: 100%;
    height: auto;
  }

  /deep/ video {
    max-width: 100%;
  }

  .agreement-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: 110px;
    background-color: #fff;

    .is-read-container {
      box-sizing: border-box;
      margin: 0 0 10px 0;
      padding: 0 14px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    .van-checkbox {
      font-size: 16px;

      /deep/ .van-checkbox__label {
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .btn-container {
      box-sizing: border-box;
      padding: 0 10px;
      width: 100%;
    }

    .van-button {
      padding: 0 12px;
      font-size: 16px;
    }

  }
}
</style>
